<template>
    <div class="indexStyle">
        <div class="head_img" :style="`height:${banner_height}px`"></div>
        <div class="service">
            <div class="h2">移动营销模式</div>
            <ul>
                <li v-for="(item,index) in serviceList" :key="index">
                    <div class="text">
                        <img :src="item.img" alt="">
                        <p>{{item.title}}</p>
                    </div>
                </li>
                <div class="list" v-for="item in (rowNum - serviceList.length % rowNum)"><div v-if="serviceList.length % rowNum > 0"></div></div>
            </ul>
        </div>
        <div class="oneStop">
            <div class="h2" v-show="oneStopShow">一站式网络营销服务</div>
                <ul v-show="oneStopShow">
                    <li v-for="(item,index) in oneStopList" :key="index">
                        <img :src="item.img" alt="">
                        <div class="text">
                            <p>{{item.title}}</p>
                            <p>{{item.content}}</p>
                        </div>
                    </li>
                </ul>
        </div>
    <correlationCase  :caseShow="caseShow"   :caseList="marketingList" />
</div>
</template>
<script>
import correlationCase from '@/components/correlationCase'
export default {
    components:{correlationCase},
    data(){
        return{
            serviceList:[
                {
                    img:require('@/assets/image/marketing/icon13.png'),
                    title:'网络营销',
                },
                {
                    img:require('@/assets/image/marketing/icon1.png'),
                    title:'新媒体',
                },
                {
                    img:require('@/assets/image/marketing/icon2.png'),
                    title:'口碑营销',
                },
                {
                    img:require('@/assets/image/marketing/icon3.png'),
                    title:'网红大号',
                },
                {
                    img:require('@/assets/image/marketing/icon4.png'),
                    title:'短视频',
                },
                {
                    img:require('@/assets/image/marketing/icon5.png'),
                    title:'媒体传播',
                },
                {
                    img:require('@/assets/image/marketing/icon6.png'),
                    title:'媒体邀约',
                },
                {
                    img:require('@/assets/image/marketing/icon7.png'),
                    title:'效果广告',
                },
                {
                    img:require('@/assets/image/marketing/icon8.png'),
                    title:'直播营销',
                },
                {
                    img:require('@/assets/image/marketing/icon9.png'),
                    title:'红书',
                }

            ],
            oneStopList:[
                {
                    img:require('@/assets/image/marketing/icon16.png'),
                    title:'营销策划',
                    content:'营销顾问带领一个团队，免费为您的项目量身分析策划并定制推广方案！',
                },
                {
                    img:require('@/assets/image/marketing/icon15.png'),
                    title:'基础搭建',
                    content:'建站 电脑站 移动站 微官网 小程序 H5 公众号运营 等基础搭建',
                },
                {
                    img:require('@/assets/image/marketing/icon14.png'),
                    title:'网络铺垫',
                    content:'通过代运营，网站优化，新闻，百科，问答，帖子，视频，图片等手段，为您进行网络信息铺地，让网络有信息，客户能搜到，能找到。',
                },
                {
                    img:require('@/assets/image/marketing/icon10.png'),
                    title:'口碑（品牌）营销',
                    content:'在网上进行品牌宣传和口碑传播，通过全网营销，全渠道方法，进行全面的网络品牌打造，服务过上千家管理。'
                },
                {
                    img:require('@/assets/image/marketing/icon11.png'),
                    title:'效果转化',
                    content:'通过搜索广告投放，网络广告投放，短视频广告，网红卖货，等全方面的手段，实现流量，点击，咨询等最终目的。'
                },
                {
                    img:require('@/assets/image/marketing/icon12.png'),
                    title:'汇报维护',
                    content:'提供详细的营销汇报和持续的跟进维护，让您坐享网络营销服务！'
                }

            ],
            //营销策划
            marketingList:[
                {
                    img:require('@/assets/image/case/1.png'),
                    title:'营销策划系统',
                    content1:'秒杀活动',
                    content2:'宣传店铺，限时秒杀购券引流到店'
                },
                {
                    img:require('@/assets/image/case/2.png'),
                    title:'营销策划系统',
                    content1:'答题活动',
                    content2:'答题互动宣传活动，扩大品牌传播'
                },
                {
                    img:require('@/assets/image/case/3.png'),
                    title:'营销策划系统',
                    content1:'抽奖活动',
                    content2:'进行宣传促销，抽奖派券赢大奖'
                },
                {
                    img:require('@/assets/image/case/4.png'),
                    title:'营销策划系统',
                    content1:'积分活动',
                    content2:'日常打卡活动，有效增加用户粘性'
                },
            ],
            rowNum: 9, // rowNum即是每列元素的个数，4列，5列。。。更改这个值即可
            caseShow:false,
            oneStopShow:false,
            windowWidth:document.documentElement.clientWidth,
            banner_height: document.documentElement.clientWidth > 1024 ?
            document.documentElement.clientWidth * 0.21 : document.documentElement.clientWidth * 0.29,
        }
    },
    mounted(){
        window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
                if(this.windowWidth >= 1024){
                    this.banner_height = this.windowWidth * 0.21
                }else{
                    this.banner_height = this.windowWidth * 0.29
                }
            })()
        }
        if(this.windowWidth <= 1024){
            this.oneStopShow=true
            this.caseShow = true
        } 
    },
    methods:{
         // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth <= 1024)return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop
            //动画播放设置
            //一站式网络营销服务
            let oneStop = document.getElementsByClassName('oneStop')
            let oneStop_h2 = oneStop[0].getElementsByClassName('h2')
            let oneStopLi = oneStop[0].getElementsByTagName('li')
           //开发案例
            let Case = document.getElementsByClassName('case')
            let Case_h2 = Case[0].getElementsByClassName('h2')
            let Case_swiper = Case[0].getElementsByClassName('case-swiper-container')

            if(scrollTop > (oneStop[0].getBoundingClientRect().top + scrollTop) - oneStop[0].offsetHeight){
                this.oneStopShow = true
                oneStop_h2[0].classList.add('animated','fadeIn')
                oneStopLi.forEach((item,index)=>{
                    if((index + 1) % 2 == 0){
                        item.classList.add('animated','fadeInRight')
                    }else{
                        item.classList.add('animated','fadeInLeft')
                    }
                })
            }
            if(scrollTop > (Case[0].getBoundingClientRect().top + scrollTop) - Case[0].offsetHeight / 1.5){
                this.caseShow = true
                Case_h2[0].classList.add('animated','fadeIn')
                Case_swiper[0].classList.add('animated','fadeInUp')
            }
            
        } 
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
    
}
</script>

<style lang="less" scoped>
.indexStyle {
        .head_img{
            background: url('~@/assets/image/marketing/banner.png') no-repeat;
            background-size: 100%;
        }
        .service{
            
            height: 668px;
            .h2{
                padding: 65px 0 26px 0;
            }
            ul{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 1200px;
                margin: 0 auto;
                li{
                    background: url('~@/assets/image/marketing/rectangle1.png') no-repeat;
                    width: 235px;
                    height: 211px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin: 0 45px;
                    }
                    .text{
                        p{
                            margin-top: 15px;
                            font-size: 22px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                        }
                    }
                }

            }
            .list{
                content: '';
                width: 235px;
                height: 211px;
                border: 1px solid transparent;
                padding: 5px;
                overflow: hidden;
            }
        }
        .oneStop{
            height: 783px;
            background-color: #f8f8f8;
            .h2{
                padding: 65px 0 26px 0;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                width: 1200px;
                margin: 0 auto;
                li{
                    background: url('~@/assets/image/marketing/rectangle.png') no-repeat;
                    width: 600px;
                    height: 170px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background: url('~@/assets/image/marketing/rectangle_hover.png') no-repeat;
                    }
                    img{
                        margin: 0 45px;
                    }
                    .text{
                        width: 407px;
                        text-align: left;
                        p:nth-child(1){
                            font-size: 22px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                        }
                        p:nth-child(2){
                            margin-top: 10px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 300;
                            color: #666666;
                            line-height: 20px;
                        }
                    }
                }

            }     
        }
        .fadeIn{
            // animation-duration: 5s;         //动画持续时间
            animation-delay: 1s !important;              //动画延迟时间
            // animation-iteration-count: 2 !important;
        }
        .zoomIn,.fadeInRight,.fadeInLeft,.fadeInUp{
            // animation-duration: 5s;         //动画持续时间
            animation-delay: 1.5s !important;              //动画延迟时间
            // animation-iteration-count: 2 !important;
        }
    }
@media screen and (max-width: 1024px) {
    .indexStyle{
        .head_img{
            background: url('~@/assets/image/marketing/mobile_banner.png') no-repeat;
            height: 110px;
            background-size: 100%;

        }
        .service{
            height: 515px;
            .h2{
                padding: 65px 0 20px 0;
            }
            ul{
                width: 100%;
                li{
                    background: url('~@/assets/image/marketing/mobile_rectangle.png') no-repeat center center;
                    background-size: 110px 100px;
                    width: 110px;
                    height: 100px;
                    .text{
                        img{
                            width: 40px;
                            height: 40px;
                        }
                        p{
                            font-size: 13px;
                            margin-top: 5px;
                        }
                    }

                }
                .list{
                    width: 110px;
                    height: 100px;
                }
            }
        }
        .oneStop{
            height: 600px;
                .h2{
                    padding: 65px 0 20px 0;
                }
                ul{
                    width: 100%;
                    justify-content: center;
                    li{
                        background: url('~@/assets/image/marketing/mobile_rectangle1.png') no-repeat;   
                        background-size: 100% 80px;
                        width:96%;
                        height: 80px;
                        .text{
                            p:nth-child(1){
                                font-size: 13px;
                            }
                            p:nth-child(2){
                                font-size: 9px;
                                transform: scale(0.87);
                                margin-top: 0;
                                margin-left: -20px;
                                line-height: 14px;
                            }
                        }
                        img{
                            width: 35px;
                            height: 38px;
                            margin: 0 20px;
                        }
                    }
                }
            
        }
    }
}


@media screen and (min-width: 1025px) {
    .indexStyle {
        margin-top: 90px;
    }
    .mobileSlidShow {
        display: none;
    }
}
</style>